<template>
    <div v-if="draw">
        <NotificationContainer v-if="$chimera.loadTable.status === 200" :event-name="notificationEvent" />

        <v-style v-if="$chimera.loadTable.status === 200 && $chimera.loadTable.data.css">
            {{ $chimera.loadTable.data.css }}
        </v-style>

        <div :class="tableClasses">
            <TableFilterWrapper :show-filters="showFilters" :items="renderingFilters" v-model="filterData">
                <template #title>
                    <table-breadcrumb
                        v-if="breadcrumbsEndpoint"
                        :filters="activeFilters"
                        :endpoint="breadcrumbsEndpoint"
                    />

                    <h5 v-if="table && showHeader" class="text-center mb-0 py-2 over-hover table-title" ref="header">
                        <component-maker :content="`<span>${table.header}</span>`" name="table-title" />

                        <PlatonLink
                            v-if="isAdmin && isMyProject(table)"
                            class="small over-target"
                            :link="`/forms/tables/${tableId}?_target=modal`"
                        >
                            <i class="fa fa-cog"></i>
                        </PlatonLink>
                    </h5>
                </template>
                <!--				<keep-alive>-->
                <b-skeleton-wrapper :loading="showTableSkeleton">
                    <template #loading>
                        <div class="d-flex justify-content-center" v-if="showHeader">
                            <b-skeleton width="70%" height="35px" class="my-2 mb-4"></b-skeleton>
                        </div>

                        <b-skeleton-table
                            :rows="15"
                            :columns="6"
                            :table-props="{ bordered: true, striped: true }"
                        ></b-skeleton-table>
                    </template>

                    <div v-if="table" ref="container">
                        <PTable
                            v-if="columns.length > 0 && $chimera.loadTable.status === 200"
                            :columns="columns"
                            :table-name="tableName"
                            :left-fixed="$chimera.loadTable.data.leftFixed"
                            :right-fixed="$chimera.loadTable.data.rightFixed"
                            :navigateByKeyboard="navigateByKeyboard"
                            :extra-columns="extraColumns"
                            :groupBy="groupingBy"
                            :split-rows-by="splitRowsBy"
                            :max-height="maxHeight"
                            :table-data="tableData"
                            :columns-config="columnsConfig"
                            :level="tableLevel"
                            :table-id="tableId"
                            ref="table"
                        />
                        <div class="table-pagination pt-2" v-if="paginationEnabled && showPagination">
                            <b-pagination
                                v-model.number="currentPage"
                                class="mb-0"
                                :total-rows="data.total"
                                :per-page="pageSize"
                                limit="7"
                                ref="pagination"
                                last-number
                                first-number
                            >
                                <template #prev-text
                                    ><i class="fa fa-chevron-left" style="font-size: 12px"></i
                                ></template>
                                <template #next-text
                                    ><i class="fa fa-chevron-right" style="font-size: 12px"></i
                                ></template>
                            </b-pagination>
                            <p
                                class="table-pagination-text"
                                v-html="
                                    $l(
                                        'platon.paginationInfo',
                                        'Умумий <span>%total</span> тa ёзувларнинг <span>%from</span> дан <span>%to</span> гачаси кўрсатилмоқда'
                                    )
                                        .replace('%total', paginationStats.total)
                                        .replace('%from', paginationStats.from)
                                        .replace('%to', paginationStats.to)
                                "
                            ></p>
                        </div>
                    </div>

                    <ChimeraErrorsView
                        v-if="$chimera.loadTable.status >= 400"
                        style="height: 400px"
                        :endpoint="$chimera.loadTable"
                        icon="fa fa-table"
                        :access-denied-text="$l('platon.table_has_no_access', 'Бу жадвални кўришга рухсат берилмаган')"
                        :not-found-text="$l('platon.table_not_found', 'Жадвал топилмади')"
                    />
                </b-skeleton-wrapper>
                <!--				</keep-alive>-->
            </TableFilterWrapper>
        </div>

        <TableControls
            :table="table"
            class="py-2 control-buttons"
            v-if="controlButtons && table && isAdmin && isMyProject(table)"
        />
    </div>
</template>

<script>
import Btn from "@Platon/components/extended/Btn.vue"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import PTable from "./PTable.vue"
import EventsMixin, { EventNames } from "@Platon/mixins/EventsMixin"
import CodeInjectableMixin from "@Platon/mixins/CodeInjectableMixin"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import { transformTableMeta } from "@Platon/components/table/TableHelpers"
import qs from "qs"
import PlatonTableEventsMixin from "@Platon/components/table/PlatonTableEventsMixin"
import TableControls from "@Platon/components/table/TableControls.vue"
import TableFilterWrapper from "@Platon/components/table/filters/TableFilterWrapper.vue"
import ChimeraErrorsView from "@Platon/components/misc/ChimeraErrorsView.vue"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import { isObjDiff } from "@Platon/core/helpers"
import PublicPageMixin from "@Platon/mixins/PublicPageMixin"
import { DEV_MODE } from "@Platon/const"
import ModalRouteMixin from "@Platon/mixins/ModalRouteMixin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"
import NotificationContainer from "@Platon/components/extended/NotificationContainer"
import TableBreadcrumb from "@Platon/components/table/TableBreadcrumb"
import ComponentMaker from "@Platon/components/builder/items/ComponentMaker"

/**
 * @param {object} filters
 *
 * removes nulls from filter, if functions inside methods page
 */
function cleanupFilters(filters) {
    for (let key of Object.keys(filters)) {
        if (filters[key] === null || filters[key] === undefined || filters[key] === "") {
            delete filters[key]
        }

        if (key === "_page" && filters[key] == "1") delete filters[key]
    }

    return filters
}

const TABLE_DATA_SOURCE_API = "platon_api"

export default {
    name: "platon-table",
    components: {
        TableBreadcrumb,
        ChimeraErrorsView,
        TableFilterWrapper,
        TableControls,
        PlatonLink,
        Btn,
        PTable,
        NotificationContainer,
        ComponentMaker
    },

    mixins: [
        CodeInjectableMixin,
        PermissionMixin,
        EventsMixin,
        ModalRouteMixin,
        PlatonTableEventsMixin,
        PublicPageMixin,
        NavigationMixin
    ],

    props: {
        tableName: {
            type: String
        },

        autoHeight: {
            type: Boolean,
            default: true
        },

        extraColumns: {
            type: Array,
            default: () => []
        },

        controlButtons: {
            type: Boolean,
            default: true
        },

        showHeader: {
            type: Boolean,
            default: false
        },

        paginationEnabled: {
            type: Boolean,
            default: true
        },

        showFilters: {
            type: Boolean,
            default: true
        },

        tableClasses: {
            type: String,
            default: ""
        },

        tableFilters: {
            default: () => ({})
        },

        extraFilters: {
            default: () => ({})
        },

        extraData: {
            default: () => {}
        },

        externalFilters: {
            default: () => [],
            type: Array
        },

        useExternalButtons: {
            type: Boolean,
            default: false
        },

        useExternalFilters: {
            type: Boolean,
            default: false
        },

        navigateByKeyboard: {
            default: false,
            type: Boolean
        },

        draw: {
            default: true,
            type: Boolean
        },

        maxHeight: {
            type: String,
            default: undefined
        }
    },

    provide() {
        return {
            table: this,
            platonTable: this,
            tableName: this.tableName,
            tableLevel: this.tableLevel
        }
    },

    data() {
        let currentPage = Number(this.tableFilters && this.tableFilters["_page"]) || 1
        return {
            currentPage: currentPage,
            filterData: {},
            manualFiltersUpdate: false,
            _lockFilters: true,
            filterElements: new Map(),
            breadcrumbsEndpoint: null,
            splitRowsBy: undefined
        }
    },

    chimera: {
        loadTable() {
            let params = qs.stringify(this.activeFilters, { arrayFormat: "brackets" })
            let url = this.addPublicRoutePrefix(`tables/${this.tableName}/meta?` + params)

            return {
                url: url,
                auto: false,
                transformer: transformTableMeta,
                on: {
                    success: async (endpoint) => {
                        this.$clearEvents()

                        if (endpoint.data && endpoint.data.js) this.injectScopeJs(endpoint.data.js, this)

                        // fetch data only after meta fetched
                        await this.$nextTick()

                        this.afterMetaLoaded(endpoint.data)

                        await this.$chimera.loadTableData.reload()

                        this._lockFilters = false

                        this.$emit("onMetaLoaded", endpoint.data)

                        this.$plugins.triggerEvent(PluginEvents.OnTableOpened, this.$route)
                    }
                }
            }
        },

        loadTableData() {
            let url
            let http

            if (
                this.table &&
                this.table.dataSourceType &&
                this.table.dataSourceType.toLowerCase() === TABLE_DATA_SOURCE_API
            ) {
                url = this.table.dataUrl
                http = this.$api
            } else {
                http = this.$http
                let params = qs.stringify(this.activeFilters, { arrayFormat: "brackets" })
                url = this.addPublicRoutePrefix(`tables/${this.tableName}/data?` + params)
            }

            return {
                url,
                axios: http,
                interval: ((this.table && this.table.refreshTimeout) || 3600) * 1000,
                auto: false,
                keepData: false,
                on: {
                    success: async ({ data }) => {
                        this.$nextTick(() => {
                            this.$emit("tableLoaded", this)
                        })

                        await this.$nextTick()

                        this.afterDataLoaded(data)
                    }
                }
            }
        }
    },

    computed: {
        pageSize() {
            let size = 10

            try {
                if (this.$route.query._page_size) {
                    size = this.$route.query._page_size
                } else {
                    size = this.$chimera.loadTable.data.paginationSize
                }
            } catch (e) {}

            return size
        },

        tableLevel() {
            try {
                return this.table.level
            } catch {
                return 1
            }
        },

        /**
         * @return {PlatonTable}
         */
        table() {
            try {
                return this.$chimera.loadTable.data
            } catch (e) {
                return {}
            }
        },

        filtersAndButtons() {
            try {
                return [
                    ...(this.$chimera.loadTable.data.filters || []).map((f) => ({ ...f, __type: "table-filter" })),
                    ...(this.$chimera.loadTable.data.buttons || []).map((f) => ({ ...f, __type: "table-button" }))
                ]
            } catch {
                return []
            }
        },

        /**
         * Buttons and filters to render
         */
        renderingFilters() {
            let filters = []

            if (this.useExternalFilters) {
                filters.push(...this.externalFilters.filter((x) => x.__type === "table-filter"))
            } else {
                filters.push(...this.filtersAndButtons.filter((x) => x.__type === "table-filter"))
            }

            if (this.useExternalButtons) {
                filters.push(...this.externalFilters.filter((x) => x.__type === "table-button"))
            } else {
                filters.push(...this.filtersAndButtons.filter((x) => x.__type === "table-button"))
            }

            return filters
        },

        /**
         * @return {TableColumn[]}
         */
        columns() {
            try {
                return this.table.columns
            } catch (e) {
                return []
            }
        },

        groupingBy() {
            return this.table.groupingField
        },

        columnsConfig() {
            return this.table.userColumns?.column_config || {}
        },

        tableId() {
            return this.table?.id
        },

        notificationEvent() {
            return this.table.attributes.notification_event
        },

        data() {
            try {
                return this.$chimera.loadTableData.data.data
            } catch (e) {
                return {}
            }
        },

        tableData() {
            try {
                return this.transformTableData(this.$chimera.loadTableData?.data) || []
            } catch (e) {
                return []
            }
        },

        showPagination() {
            return (
                this.$chimera.loadTable.status === 200 &&
                this.$chimera.loadTableData.status === 200 &&
                this.columns.length > 0 &&
                this.data.total > this.pageSize &&
                this.pageSize > 0
            )
        },

        activeFilters() {
            let params = { ...this.extraFilters }

            Object.keys(this.filterData || {}).forEach((f) => {
                if (this.filterData[f] === undefined) return

                if (typeof this.filterData[f] === "string" && this.filterData[f].length === 0) {
                    return
                }

                if (this.filterData[f] !== null && this.filterData[f] !== false) {
                    params[f] = this.filterData[f]
                }
            })

            return params
        },

        showTableSkeleton() {
            return this.$chimera.loadTable.loading || this.$chimera.loadTableData.loading
        },

        paginationStats() {
            const obj = {
                total: this.data.total,
                from: (this.currentPage - 1) * this.pageSize + 1,
                to: (this.currentPage - 1) * this.pageSize + this.data.results.length
            }

            return obj
        },

        params() {
            try {
                return this.$chimera.loadTable.data.params
            } catch {
                return {}
            }
        }
    },

    methods: {
        onFormSaved() {
            this.$chimera.loadTable.fetch(true)
        },

        reload() {
            this.$chimera.loadTable.fetch(true)
        },

        resetPage() {
            this.currentPage = 1
        },

        setFilter(key, value, forceEmit = false) {
            this.$set(this.filterData, key, value)

            if (!this.manualFiltersUpdate || forceEmit) {
                this.emitFilters()
            }
        },

        emitFilters() {
            this.$emit("onFiltersChange", cleanupFilters({ ...this.filterData }))
        },

        /**
         * @param {number|string} by
         */
        findColumnByIdOrDataField(by) {
            /**
             * @param {TableColumn[]} columns
             * @return {?TableColumn}
             */
            function deepFind(columns) {
                for (let c of columns) {
                    if (c.dataField === by || c.id === by) {
                        return c
                    }

                    if (Array.isArray(c.children) && c.children.length > 0) {
                        let found = deepFind(c.children)

                        if (found) {
                            return found
                        }
                    }
                }

                return null
            }

            return deepFind(this.columns)
        },

        /**
         * @param {string|number} column
         * @param {string} attr
         * @param {any} value
         */
        setColumnAttr(column, attr, value) {
            try {
                const col = this.findColumnByIdOrDataField(column)

                this.$set(col, attr, value)
            } catch (e) {
                if (DEV_MODE) console.error(e)
            }
        },

        exportXlsx() {
            this.$refs.table && this.$refs.table.exportXlsx()
        },

        tableColumnFilter() {
            this.$refs.table.showColumnFilter()
        }
    },

    mounted() {
        this.$chimera.loadTable.fetch()

        window.addEventListener(EventNames.FORM_SAVED, this.onFormSaved)
    },

    beforeDestroy() {
        window.removeEventListener(EventNames.FORM_SAVED, this.onFormSaved)
    },

    watch: {
        currentPage: {
            // immediate: true,
            handler(page, old) {
                if (page !== old) this.setFilter("_page", page > 1 ? page : 1, true)
            }
        },

        tableFilters: {
            immediate: true,
            handler(filters, oldFilters) {
                if (isObjDiff(filters, this.filterData)) {
                    let newFilters = this.onFiltersUpdating(cleanupFilters({ ...this.filters, ...filters }), oldFilters)

                    this.$set(this, "filterData", newFilters)

                    if (!this.manualFiltersUpdate) {
                        this.emitFilters()
                    }
                }
            }
        },

        activeFilters(filters) {
            let newPage = null

            if (Number.isInteger(Number(filters["_page"])) && Number(filters["_page"]) !== this.currentPage)
                newPage = Number(filters["_page"])
            else if (!filters["_page"]) newPage = 1

            if (this.manualFiltersUpdate) {
                if (newPage) this.currentPage = newPage
                return
            }

            if (this._lockFilters) {
                return
            }

            if (newPage) this.currentPage = newPage

            this.$chimera.loadTable.fetch()
        }
    }
}
</script>
